.icon-spin {
  display: inline-block;
  animation: spin 6s infinite ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-spin.stop {
  animation-play-state: paused;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

svg.icon-flip {
  display: inline-block;
  width: 38px;
  height: 38px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  animation: flip 3s infinite ease-in-out;
}
